<script>
import { BTN_COLOR_SCHEME } from '~/constants/ui/button'

export default {
  name: 'RButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    isLoading: {
      type: Boolean,
    },
    fontStyles: {
      type: String,
      default: 'text-btn-text',
    },
  },
  data() {
    return {
      BTN_COLOR_SCHEME,
    }
  },
}
</script>

<template>
  <button
    v-bind="$attrs"
    :disabled="disabled"
    :class="[
      !isLoading
        ? `${BTN_COLOR_SCHEME[color]}`
        : `${BTN_COLOR_SCHEME[`${color}-loading`]}`,
      fontStyles,
    ]"
    class="disabled:bg-secondary-100 flex whitespace-nowrap items-center justify-center gap-2 rounded-md px-6 py-3 duration-100"
  >
    <svg
      v-show="isLoading"
      class="w-5 h-5 text-white animate-spin absolute"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      />
      <path
        class="opacity-75"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        fill="currentColor"
      />
    </svg>
    <div :class="isLoading ? 'invisible' : 'visible'">
      <slot>{{ text }}</slot>
    </div>
  </button>
</template>

<style scoped></style>
